import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

const TermsOfUse = () => {
  return (
    <Row className="w-100">
      <Col xs={12} md={9} lg={6} className="mx-auto p-2">
        <Card className="w-100">
          <CardBody className="p-4">
            <div className="text-center">
              <Link to="/">
                <img src="/images/lumensol-v.png" alt="iLumen8 Reporting" className="mb-4" />
              </Link>
            </div>
            <h1>Terms of use</h1>
            <div>
              <p className="pb-1 m-0">
                Welcome to iLumen8's terms of use. We've done our best to explain our terms without using too many legal terms and jargon.
                It's long but please carefully and thoroughly read this whole document, because it sets out your rights (and ours). Our
                terms of use apply to the use of this Website and by accessing this Website and/or using the Services you agree to be bound
                by the terms set out below. If you do not agree to be bound by these terms you may not use or access the Website. If you
                have any queries about these terms and conditions, please email us at{" "}
                <a href="mailto:info@lumensol.co.uk">info@lumensol.co.uk</a>
              </p>
              <ol className="pb-2">
                <li className="pb-1">
                  DEFINITIONS
                  <p className="pb-1 m-0">"Analytics Software" means any cloud or desktop based analytics software.</p>
                  <p className="pb-1 m-0">"Agreement" means these Terms of Use.</p>
                  <p className="pb-1 m-0">
                    "Confidential Information" includes all information exchanged between the parties to this Agreement, whether in writing,
                    electronically or orally, including the Software but does not include information which is, or becomes, publicly
                    available other than through unauthorised disclosure by the other party.
                  </p>
                  <p className="pb-1 m-0">"Data" means any data in the Software.</p>
                  <p className="pb-1 m-0">
                    "Intellectual Property Right" means any patent, trade mark, service mark, copyright, moral right, right in a design,
                    know-how and any other intellectual or industrial property rights, anywhere in the world whether or not registered.
                  </p>
                  <p className="pb-1 m-0">
                    "Personal Data" means any information relating to an identified or identifiable natural person within the Data, and such
                    other information as afforded similar protection under applicable data protection law.
                  </p>
                  <p className="pb-1 m-0">
                    "Software" means the software available (as may be changed or updated from time to time by iLumen8) via the Website.
                  </p>
                  <p className="pb-1 m-0">
                    "iLumen8" means Lumensol Ltd. a registered UK company in Priory Street. Newport Pagnell. MK16 9BL{" "}
                  </p>
                  <p className="pb-1 m-0">
                    "Subscriber" means the person who registers to use the Software, and, where the context permits, includes any entity on
                    whose behalf that person registers to use the Software.
                  </p>
                  <p className="pb-1 m-0">"You" means the Subscriber. </p>
                  <p className="pb-1 m-0">"Your" has a corresponding meaning. </p>
                  <p className="pb-1 m-0">
                    "Website" means the Internet site at the domain app.lumensol.co.uk, or any other site operated by Lumensol.{" "}
                  </p>
                </li>
                <li className="pb-1">
                  USE OF SOFTWARE
                  <p className="pb-1 m-0">
                    iLumen8 grants You the right to access and use the Software via the Website. This right is non-exclusive and
                    non-transferable and limited by these Terms. You acknowledge that accessibility to any company's financial data via the
                    Software is set through the company's respective iLumen8 Software.
                  </p>
                </li>
                <li className="pb-1">
                  YOUR OBLIGATIONS
                  <p className="pb-1 m-0">
                    You must only use the Software and Website for Your own lawful business purposes, in accordance with these Terms and any
                    notice sent by iLumen8 or condition posted on the Website. You may use the Software and Website on behalf of others or
                    in order to provide services to others but if You do so You must ensure that You are authorised to do so and that all
                    persons for whom or to whom services are provided comply with and accept all terms of this Agreement that apply to You.
                  </p>
                  <p className="pb-1 m-0">
                    You must ensure that all usernames and passwords required to access the Software are kept secure and confidential. You
                    will immediately notify iLumen8 of any unauthorised use of Your passwords or any other breach of security and iLumen8
                    will reset Your password. You must take all other actions that iLumen8 reasonably deems necessary to maintain or enhance
                    the security of iLumen8's computing systems and networks and Your access to the Software. As a condition of these Terms,
                    when accessing and using the Software, You must: not attempt to undermine the security or integrity of iLumen8's
                    computing systems or networks or, where the Software is hosted by a third party, that third party's computing systems
                    and networks; not use, or misuse, the Software in any way which may impair the functionality of the Software or Website,
                    or other systems used to deliver the Software or impair the ability of any other user to use the Software or Website;
                    not attempt to gain unauthorised access to any materials other than those to which You have been given express
                    permission to access or to the computer system on which the Software is hosted; not transmit, or input into the
                    Software, any: files that may damage any other person's computing devices or software, content that may be offensive, or
                    material or Data in violation of any law (including Data or other material protected by copyright or trade secrets which
                    You do not have the right to use); and not attempt to modify, copy, adapt, reproduce, disassemble, decompile or reverse
                    engineer the Software or the Website except as is strictly necessary to use either of them for normal operation.
                  </p>
                  <p className="pb-1 m-0">
                    By using any third-party functionality within the Software You confirm that You have read and accept the third-party's
                    terms and conditions. Use of the Software may be subject to limitations. Any such limitations will be advised. You
                    indemnify iLumen8 against all claims, costs, damage and loss arising from Your breach of any of these terms or any
                    obligation You may have to iLumen8.
                  </p>
                </li>
                <li className="pb-1">
                  CONFIDENTIALITY AND PRIVACY
                  <p className="pb-1 m-0">
                    Unless the relevant party has the prior written consent of the other or unless required to do so by law: Each party will
                    preserve the confidentiality of all Confidential Information of the other obtained in connection with these Terms.
                    Neither party will, without the prior written consent of the other, disclose or make any Confidential Information
                    available to any person, or use the same for its own benefit, other than as contemplated by these Terms. Each party's
                    obligations under this clause will survive termination of these Terms. You expressly give iLumen8 the right to use Your
                    information and Your Data to resolve Your support issues and/or queries and produce aggregated reports.
                  </p>
                  <p className="pb-1 m-0">
                    You must comply with your obligations under applicable data protection laws and you shall have sole responsibility for
                    the accuracy, quality, and legality of the Personal Data, and the means by which iLumen8 acquired the Personal Data and
                    You must establish the legal basis for processing under applicable data protection laws, including by providing all
                    notices and obtaining all consents as may be required under applicable data protection laws in order for iLumen8 to
                    process the Personal Data in order to provide the Software and as otherwise contemplated by these Terms.
                  </p>
                  <p className="pb-1 m-0">
                    Where iLumen8 processes Personal Data as a "data controller" (or similar concept under applicable data protection law),
                    iLumen8 maintains a privacy policy that covers iLumen8's processing of this Personal Data as a "data controller",
                    available here: <a href="/privacypolicy">https://app.lumensol.co.uk/privacypolicy</a>. You should read the Privacy
                    Policy and You will be taken to have acknowledged (and accepted, where acceptance is required by applicable data
                    protection laws) that Privacy Policy when You accept these Terms. You must provide or direct all relevant individuals to
                    iLumen8's Privacy Policy prior to providing their Personal Data to iLumen8.
                  </p>
                  <p className="pb-1 m-0">
                    Where iLumen8 acts as Your "data processor" (or similar concept under applicable data protection law), to the extent
                    required by applicable data protection law, iLumen8 will:
                  </p>
                  <ul className="pb-2" type="disc">
                    <li className="pb-1">
                      process any Personal Data in accordance with Your instructions unless required to do so by applicable laws. You
                      instruct iLumen8 to process the Personal Data, provide the Software and to fulfil iLumen8's obligations under these
                      Terms. iLumen8 will notify You if, in its opinion, an instruction infringes applicable data protection law and will,
                      without liability, be entitled to stop processing the Personal Data in accordance with such infringing instruction;
                    </li>
                    <li className="pb-1">
                      implement appropriate security measures to protect the Personal Data, including by ensuring any iLumen8 personnel that
                      process the Personal Data are subject to enforceable duties of confidence. In the event of a confirmed breach of
                      iLumen8's data security affecting Your Personal Data, iLumen8 will promptly notify You of the breach and cooperate in
                      the investigation of the matter;
                    </li>
                    <li className="pb-1">
                      be permitted to engage sub-processors and process the Personal Data outside the jurisdiction in which You or iLumen8
                      may be located. iLumen8 can provide you with a list of current sub-processors promptly upon your request. iLumen8
                      shall ensure that such sub-processors are subject to substantially similar provisions as iLumen8 in respect of
                      processing the Personal Data and iLumen8 remains responsible for the acts and omissions of its sub-processors;
                    </li>
                    <li className="pb-1">
                      at Your election, return or delete Your Personal Data at the end of the provision of the Software unless further
                      retention is required by applicable law or in order to defend any legal claims or for any legitimate business
                      purposes. Such deletion will be in accordance with iLumen8's document retention practices. iLumen8 shall have no
                      liability for any losses incurred by You arising from or in connection with iLumen8's inability to provide the
                      Software as a result of iLumen8 complying with a request to delete or return the Personal Data;
                    </li>
                    <li className="pb-1">
                      at your cost, provide you with commercially reasonable assistance as You may reasonably request to: respond to
                      requests from individuals to exercise their rights under applicable data protection law in respect of the Personal
                      Data; undertake data protection impact assessments; and/or consult with data protection authorities; and
                    </li>
                    <li className="pb-1">
                      at your cost, provide you with such commercially reasonable information as You may reasonably request with respect to
                      iLumen8's compliance with these "data processor" obligations under this clause 4.
                    </li>
                  </ul>
                  <p className="pb-1 m-0">
                    The details of processing of Personal Data where iLumen8 is a "data processor" under this clause 4 are as follows:
                  </p>
                  <ol className="pb-2" type="a">
                    <li className="pb-1">
                      Categories of Data Subject: (i) customer of Subscriber; (ii) suppliers of Subscriber; (iii) employees or contractors
                      of Subscriber; and (iv) other contacts of Subscriber
                    </li>
                    <li className="pb-1">
                      Categories of Personal Data: Customer controls and determines the categories of Personal Data it submits to iLumen8,
                      however, it may include: (i) names; (ii) addresses; (iii) contact details; and (iv) any other Personal Data types
                      submitted by the Customer to iLumen8, including those referred to in the Privacy Policy
                    </li>
                    <li className="pb-1">
                      The period for which the Personal Data is to be retained/duration of Processing: as per this clause 4
                    </li>
                    <li className="pb-1">Frequency of transfer: Ongoing</li>
                  </ol>
                </li>
                <li className="pb-1">
                  INTELLECTUAL PROPERTY
                  <p className="pb-1 m-0">
                    Title to, and all Intellectual Property Rights in the Software, the Website and any documentation relating to the
                    Software remain the property of iLumen8 (or its licensors). Title to, and all Intellectual Property Rights in, the Data
                    remain Your property. You grant iLumen8 a licence to use, copy, transmit, store, and back-up Your information and Data
                    for the purposes of enabling You to access and use the Software and for any other purpose related to provision of
                    services to You.
                  </p>
                  <p className="pb-1 m-0">
                    iLumen8 adheres to its best practice policies and procedures to prevent data loss, including a daily system data back-up
                    regime, but does not make any guarantees that there will be no loss of Data.
                  </p>
                </li>
                <li className="pb-1">
                  WARRANTIES AND ACKNOWLEDGEMENTS
                  <p className="pb-1 m-0">
                    You warrant that where You have registered to use the Software on behalf of another person, You have the authority to
                    agree to these Terms on behalf of that person and agree that by registering to use the Software You bind the person on
                    whose behalf You act to the performance of any and all obligations that You become subject to by virtue of these Terms,
                    without limiting Your own personal obligations under these Terms.
                  </p>
                  <p className="pb-1 m-0">
                    You acknowledge that: You are authorised to use the Software and the Website and to access the information that You
                    access using the Software and the Website (whether that information is Your own or that of anyone else). If You are
                    using the Software and accessing the Website on behalf of or for the benefit of an organisation then iLumen8 will assume
                    that You have the right to do so and that organisation will be liable for Your actions or omissions (including any
                    breach of these Terms).
                  </p>
                  <p className="pb-1 m-0">
                    iLumen8 has no responsibility to any person other than You and nothing in this Agreement confers, or purports to confer,
                    a benefit on any person other than You. If You use the Software or access the Website on behalf of or for the benefit of
                    anyone other than Yourself, You agree that: You are responsible for ensuring that You have the right to do so; You are
                    responsible for authorising any person who is given access to information or Data, and You agree that iLumen8 has no
                    obligation to provide any person access to such information or Data without Your authorisation and may refer any
                    requests for information to You; and You will indemnify iLumen8 against any claims or loss relating to: iLumen8's
                    refusal to provide any person access to Your information or Data in accordance with these Terms, and iLumen8 making
                    available information or Data to any person with Your authorisation.
                  </p>
                  <p className="pb-1 m-0">
                    iLumen8 warrants that the Software will perform in accordance with the website and promotional material, and supporting
                    documentation. Except as expressly provided in the preceding sentence, the provision of, access to, and use of, the
                    Software is on an "as is, where is" basis and at Your own risk. iLumen8 does not warrant that the use of the Software
                    will be uninterrupted or error free, but, notwithstanding Section 9 below, iLumen8 warrants that the software will be
                    available 99.9% of the time. Among other things, Your classification of items, suppliers, accounts, customers, and/or
                    invoices, in your chosen iLumen8 Software will impact the reporting provided by iLumen8. In addition, the operation and
                    availability of the systems used for accessing the Software, including public telephone services, computer networks and
                    the Internet, can be unpredictable and may from time to time interfere with or prevent access to the Software. iLumen8
                    is not in any way responsible for any such interference or prevention of Your access or use of the Software.
                  </p>
                  <p className="pb-1 m-0">
                    It is Your responsibility to check that storage of and access to Your Data via the Software and the Website will comply
                    with laws applicable to You (including any laws requiring You to retain records).
                  </p>
                  <p className="pb-1 m-0">
                    iLumen8 does not warrant that the Software will meet Your requirements or that it will be suitable for Your purposes. To
                    avoid doubt, all implied conditions or warranties are excluded in so far as is permitted by law, including (without
                    limitation) warranties of merchantability and fitness for purpose iLumen8 represents and warrants that it owns all
                    right, title and interest to the Software and will indemnify and hold You harmless should a claim of infringement be
                    raised by a third party. iLumen8 will either secure for You the right to continue accessing and using the Software or
                    refund all fees paid to date.
                  </p>
                  <p className="pb-1 m-0">
                    You warrant and represent that You are acquiring the right to access and use the Software and agreeing to these Terms
                    for the purposes of a business and that, to the maximum extent permitted by law, any statutory consumer guarantees or
                    legislation intended to protect non-business consumers in any jurisdiction does not apply to the supply of the Software,
                    the website or these Terms.
                  </p>
                </li>
                <li className="pb-1">
                  LIMITATION OF LIABILITY
                  <p className="pb-1 m-0">
                    To the maximum extent permitted by law, neither party shall be liable to the other party for any indirect or
                    consequential damages and each party's liability and responsibility to You (or any other person) in contract, tort, or
                    otherwise, for any loss (profits and savings) or damage ("Damages") arising out of this Agreement or resulting, directly
                    or indirectly, from the use of, or reliance on, the Software will not exceed the fees paid to iLumen8, unless such
                    Damages result from the gross negligence or intentional misconduct of iLumen8.
                  </p>
                  <p className="pb-1 m-0">
                    You acknowledge that iLumen8 will accept no liability in the event a company has incorrectly set employee/user access in
                    its analytics Software and employees/users are then able to access confidential financial information via the Software.
                    If You are not satisfied with the Software and iLumen8 has not breached any of its representations or warranties, Your
                    sole and exclusive remedy is to terminate these Terms.
                  </p>
                </li>
                <li className="pb-1">
                  TERMINATION
                  <p className="pb-1 m-0">
                    You may terminate the use of iLumen8's services at any time by cancelling Your account. Your Data shall be deleted
                    within 30 days upon request by You.
                  </p>
                </li>
                <li className="pb-1">
                  HELP DESK
                  <p className="pb-1 m-0">
                    If You need technical help, please go directly to your Lumensol contact or email{" "}
                    <a href="mailto:info@lumensol.co.uk">info@lumensol.co.uk</a>
                  </p>
                  <p className="pb-1 m-0">
                    Whilst iLumen8 intends that the Software should be available 24 hours a day, seven days a week, it is possible that on
                    occasion it may be unavailable to permit maintenance or other development activity to take place. If for any reason
                    iLumen8 interrupts the Software for longer periods than iLumen8 would normally expect, we will publish in advance
                    details of such activity on the Website.
                  </p>
                </li>
                <li className="pb-1">
                  GENERAL
                  <p className="pb-1 m-0">
                    These Terms, together with the terms of any other notices or instructions given to You under these terms, supersede and
                    extinguish all prior agreements, representations (whether oral or written), and understandings and constitute the entire
                    agreement between You and iLumen8 relating to the Software and the other matters dealt with in these Terms.
                  </p>
                  <p className="pb-1 m-0">
                    If either party waives any breach of these Terms, this will not constitute a waiver of any other breach. No waiver will
                    be effective unless made in writing. Neither party will be liable for any delay or failure in performance of its
                    obligations under these Terms if the delay or failure is due to any cause outside its reasonable control. You may not
                    assign or transfer any rights to any other person without iLumen8's prior written consent, which shall not be
                    unreasonably withheld.
                  </p>
                  <p className="pb-1 m-0">
                    This Agreement and any disputes or claims arising out of or in connection with it or its subject matter or formation
                    shall be governed by and construed in accordance with the laws of England and Wales.
                  </p>
                  <p className="pb-1 m-0">
                    If any part or provision of these Terms is invalid, unenforceable or in conflict with the law, that part or provision is
                    replaced with a provision which, as far as possible, accomplishes the original purpose of that part or provision.
                  </p>
                  <p className="pb-1 m-0">
                    Any notice given under these Terms by either party to the other must be in writing by email and will be deemed to have
                    been given on transmission. Notices to iLumen8 must be sent to{" "}
                    <a href="mailto:accounts@lumensol.co.uk">accounts@lumensol.co.uk</a> or to any other email address notified by email to
                    You by iLumen8. Notices to You will be sent to the email address You provided when setting up Your access to the
                    Software. A person who is not a party to these Terms has no right to benefit under or to enforce any term of these
                    Terms.
                  </p>
                  <p className="pb-1 m-0">These Terms were last updated on 19 December 2024.</p>
                </li>
              </ol>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default TermsOfUse;
