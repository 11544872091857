import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <Row className="w-100">
      <Col xs={12} md={9} lg={6} className="mx-auto p-2">
        <Card className="w-100">
          <CardBody className="p-4">
            <div className="text-center">
              <Link to="/">
                <img src="/images/lumensol-v.png" alt="iLumen8 Reporting" className="mb-4" />
              </Link>
            </div>
            <h1>Privacy Policy</h1>
            <div>
              <ol className="pb-2">
                <li className="pb-1">
                  <strong>Who are we?</strong>
                </li>
                <ol className="pb-2">
                  <li className="pb-1">
                    Lumensol Ltd, together with its subsidiaries and affiliates listed in Appendix 1, provides integrated analytical tools
                    and reporting solutions to social housing customers.
                  </li>
                  <li className="pb-1">
                    We are the controller of the personal data processed under this privacy policy. The relevant data controllers of your
                    personal data will depend on where you are located, the products and/or services you purchase, the
                    website(s)/application(s) you visit and the nature of the processing activities.
                  </li>
                  <li className="pb-1">
                    Please see Appendix 1 for a list of our subsidiaries and affiliates and how to contact them. References in this policy
                    to&nbsp;<strong>"we&rdquo;</strong>,&nbsp;<strong>&ldquo;us&rdquo;</strong>&nbsp;and&nbsp;
                    <strong>&ldquo;our&rdquo;</strong>&nbsp;are to the relevant data controller(s).
                  </li>
                </ol>
                <li className="pb-1">
                  <strong>About this privacy policy</strong>
                </li>
                <ol className="pb-2">
                  <li className="pb-1">
                    This privacy policy tells you about how we process personal data both offline (e.g. via phone, mail, in-person meetings
                    and other correspondence) and online (e.g. when our website, applications and services are used).
                  </li>
                  <li className="pb-1">
                    This privacy policy is limited to the personal data that we process as a controller, which predominantly consists of
                    personal data relating to representatives of our corporate clients, partners and vendors and visitors to our websites.
                    We refer to such individuals as &ldquo;you&rdquo; and &ldquo;your&rdquo; in this privacy policy.
                  </li>
                  <li className="pb-1">
                    This privacy policy does not cover the processing of our personal data where we act as a data processor (including where
                    we process personal data relating to customers/end-users of our clients as part of the provision of our services to our
                    clients). Our handling of this personal data is governed by the applicable{" "}
                    <a href="/termsofuse">https://app.lumensol.co.uk/termsofuse</a>
                  </li>
                  <li className="pb-1">
                    Our websites/applications may contain links to other third-party websites or applications that we do not operate or
                    control. These websites and applications may have their own privacy notices or policies, which will apply to any
                    personal data processed by them.
                  </li>
                </ol>
                <li className="pb-1">
                  <strong>Country specific provisions</strong>
                  <p className="pb-1 m-0">The following only applies to this privacy policy: UK and EEA specific provisions</p>
                </li>
                <li className="pb-1">
                  <strong>What personal data do we collect?</strong>
                </li>
                <ol className="pb-2">
                  <li className="pb-1">
                    In most cases, we will collect personal data directly from you. However, we may also combine personal data collected
                    from one source with personal data that we have collected from other sources, such as when you connect a third-party
                    account or service.
                  </li>
                  <li className="pb-1">We may collect the following categories of personal data:</li>
                  <ol className="pb-2" type="a">
                    <li className="pb-1">
                      <strong>Personal data in connection with the use of our services</strong>
                      <p className="pb-1 m-0">
                        This may include name, email address, other contact details, business information, shipping address, billing
                        address, marketing preferences, account information (username), and other information provided as part of a user
                        profile.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>Transaction information</strong>
                      <p className="pb-1 m-0">
                        We collect personal data when you transact with us. This may include biographical information, contact information,
                        telephone number, and payment and invoicing information.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>Correspondence and interactions with us</strong>
                      <p className="pb-1 m-0">
                        We collect personal data where you contact us or we contact you. This may include your name and contact information,
                        account information, your order information, your queries, and other personal data you choose to provide to us. We
                        also collect personal data provided to us through a testimonial, feedback, review or comment.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>Social media information</strong>
                      <p className="pb-1 m-0">
                        We collect personal data when you interact with us on social media. These social media platforms may set cookies and
                        other tracking technologies on your device when you visit their pages. The output of such information may be
                        provided to us (usually for statistical purposes to see how users interact with our content on social medial
                        platforms).Information about how these platforms process your personal data can usually be found on their websites.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>Survey information </strong>
                      <p className="pb-1 m-0">
                        We collect personal data where you complete surveys that we may use for research purposes. This may include your
                        name and contact information and other personal data you choose to provide us.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>Information stored on a device</strong>
                      <p className="pb-1 m-0">
                        We may access metadata and information associated with other files stored on your device such as IP address, domain
                        name, browser version and operating system, traffic data, web logs and other communication data, and device
                        identifiers. Where required, we will ask for your consent prior to collecting device information.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>Location Information</strong>
                      <p className="pb-1 m-0">
                        With your permission (where required by applicable law), we may collect information about the general location of a
                        device.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>Collection and use of public information</strong>
                      <p className="pb-1 m-0">
                        We may also process personal data that is publicly available for the purposes set out in this privacy policy.
                      </p>
                    </li>
                  </ol>
                </ol>
                <li className="pb-1">
                  <strong>Why do we use personal data?</strong>
                  <p className="pb-1 m-0">We may use your personal data for the purposes set out below.</p>
                  <ol className="pb-2" type="a">
                    <li className="pb-1">
                      <strong>For account registration</strong>
                      <p className="pb-1 m-0">
                        We use personal data to open and manage your accounts. This may include name, address, phone number, billing
                        information, and email address.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>To conduct our business and provide our services</strong>
                      <p className="pb-1 m-0">
                        To conduct our business, including to enable use of our services and our websites/applications to respond to
                        queries, to carry out our obligations arising from any agreements entered into and to contact customers or potential
                        customers in connection with our business and services.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>To provide communications (about updates and changes) and to provide technical and customer support</strong>
                      <p className="pb-1 m-0">
                        To communicate about updates to our business, services and websites/applications; to provide customer and technical
                        support; to respond to requests, comments, questions, or concerns from customers or potential customers. Such
                        communications are provided by various means, including emails, telephone calls, video calls and internet chat.
                        Calls may be recorded for training and monitoring purposes and a transcript of the internet chat sessions may be
                        retained to resolve questions or issues related to our services.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>To facilitate payments</strong>
                      <p className="pb-1 m-0">
                        To take payment from you when you sign-up for certain features; to provide you with refunds and to process payments
                        when you transact with us.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>To allow use of the interactive features of our websites/applications</strong>
                      <p className="pb-1 m-0">
                        To allow testimonials, reviews or comments. Please note that such testimonial, review or comment may be read,
                        collected and used by anyone.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>
                        To ensure our websites/applications content is relevant and to maintain the security of our websites/applications
                      </strong>
                      <p className="pb-1 m-0">
                        To ensure that content from our websites/applications are presented in the most effective and secure manner.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>For analytics and business development purposes, including feedback and surveys</strong>
                      <p className="pb-1 m-0">
                        To analyse personal data in order to better understand our business, services and our customers' services and
                        marketing requirements, and to better understand our business and develop our services and websites/applications.
                        This may include aggregating and/or de-identifying your personal data.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>To provide marketing materials</strong>
                      <p className="pb-1 m-0">
                        To provide updates and offers, where customers or potential customers have chosen to receive these communications.
                        We may also use personal data for marketing our own and our selected business partners' products and services by
                        mail, email, SMS, phone and social media. Where required by law, we will ask for consent to conduct these types of
                        marketing and provide an option to unsubscribe from further marketing communications.
                      </p>
                      <p className="pb-1 m-0">
                        Please note that even if you opt-out of receiving marketing communications, you may still receive communications
                        from us that are important or related to your interactions with us, or otherwise as required by law.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>To enforce our terms, in connection with legal or regulatory obligations and to manage claims</strong>
                      <p className="pb-1 m-0">
                        We may process personal data to enforce our terms and to comply with our legal and regulatory requirements or
                        dialogue with regulators/judicial proceedings/court orders and manage claims and litigation (as applicable) which
                        may include disclosing personal data to relevant third parties, the court service and/or regulators or law
                        enforcement agencies in connection with enquiries, proceedings or investigations by such parties anywhere in the
                        world or where compelled to do so.
                      </p>
                    </li>
                    <li className="pb-1">
                      <strong>To reorganise or make changes to our business</strong>
                      <p className="pb-1 m-0">
                        In the event that we: (i) are subject to negotiations for the sale of our business or part thereof to a third party;
                        (ii) are sold to a third party; or (iii) undergo a reorganisation, we may need to transfer personal data to the
                        relevant third party (or its advisors) as part of any due diligence process for the purpose of analysing any
                        proposed sale or re-organisation. We may also need to transfer personal data to that re-organised entity or third
                        party after the sale or reorganisation.
                      </p>
                    </li>
                  </ol>
                  <li className="pb-1">
                    <strong>Cookies and similar technologies</strong>
                    <p className="pb-1 m-0">
                      We use cookies and similar technologies. For more information, please refer to our respective entities' cookies
                      policies <a href="https://www.lumensol.co.uk/">https://www.lumensol.co.uk/</a>
                    </p>
                  </li>
                  <li className="pb-1">
                    <strong>Who do we share personal data with and why? </strong>
                    <ol className="pb-2">
                      <li className="pb-1">
                        We may share or disclose personal data for the purposes set out in this privacy policy to third parties. These third
                        parties include service providers, our advisors and partners who assist and enable us to use the personal data to,
                        for example, support delivery of or provide functionality on the website or services, or to market or promote our
                        goods and services to you.
                      </li>
                      <li className="pb-1">
                        We may share your personal data we collect and process across our subsidiaries and affiliates.
                      </li>
                      <li className="pb-1">
                        We may also need to transfer personal data in the context of a reorganisation or sale of all or part of our
                        business.
                      </li>
                      <li className="pb-1">
                        Any personal data made available publicly, such as reviews, comments or testimonials, may be shared with other users
                        of our website.
                      </li>
                      <li className="pb-1">
                        We may disclose personal data as required by law or court orders, and when we believe that disclosure is necessary
                        to protect our rights or comply with a court order or other legal process.
                      </li>
                    </ol>
                  </li>
                  <li className="pb-1">
                    <strong>How do we protect personal data?</strong>
                    <ol className="pb-2">
                      <li className="pb-1">We take commercially reasonable efforts to protect the personal data under our control.</li>
                      <li className="pb-1">
                        Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our best to
                        protect personal data, we cannot guarantee the security of it transmitted to our websites, applications or through
                        communications.
                      </li>
                      <li className="pb-1">
                        If you are given or you create user credentials (such as username and passwords), please keep these safe and do not
                        disclose these to third parties.
                      </li>
                    </ol>
                  </li>
                  <li className="pb-1">
                    <strong>How long do we keep your personal data?</strong>
                    <ol className="pb-2">
                      <li className="pb-1">Our retention periods for personal data are based on business needs and legal requirements.</li>
                      <li className="pb-1">
                        We will retain personal data while we are using it for purposes described in this privacy policy. We may continue to
                        retain it after we have ceased such uses for certain legitimate business purposes. We may also continue to retain
                        personal data to meet our legal requirements or to defend or exercise our legal rights.
                      </li>
                      <li className="pb-1">
                        The length of time for which we will retain personal data will depend on the purposes for which we need to retain
                        it. After we no longer need to retain personal data, we will delete it, anonymise it, or securely destroy it.
                      </li>
                    </ol>
                  </li>
                  <li className="pb-1">
                    <strong>What are your rights?</strong>
                    <ol className="pb-2">
                      <li className="pb-1">We currently only operate in the UK which have the same data protection laws.</li>
                      <li className="pb-1">In general, you have rights to:</li>
                      <ul className="pb-2" type="disc">
                        <li className="pb-1">stop receiving marketing communications;</li>
                        <li className="pb-1">require us to update any inaccuracies in the personal data we hold;</li>
                        <li className="pb-1">request a copy of your personal data;</li>
                        <li className="pb-1">withdraw your consent (where our processing activity is based on your consent);</li>
                        <li className="pb-1">require us to delete personal data;</li>
                        <li className="pb-1">restrict how we use your personal data whilst a complaint is being investigated;</li>
                        <li className="pb-1">
                          object to our processing of your personal data (where our processing is based on our legitimate interests); and
                        </li>
                        <li className="pb-1">
                          ask us not to reach decisions that have legal or significant effects on you using automated processing or
                          profiling.
                        </li>
                      </ul>
                      <li className="pb-1">
                        To submit a request regarding personal data by email, please contact us as per section 12 below.
                      </li>
                      <li className="pb-1">
                        If you are not satisfied with how we are processing your personal data you can also contact your Data Protection
                        Authority.
                      </li>
                    </ol>
                  </li>
                  <li className="pb-1">
                    <strong>Contacting us</strong>
                    <p className="pb-1 m-0">
                      If you have any queries or complaints about our use of personal data, please contact us as follows:
                      <br />
                      Mathew Bishop
                      <br />
                      Mathew.bishop@lumensol.co.uk
                    </p>
                  </li>
                  <li className="pb-1">
                    <strong>Updates to this privacy policy</strong>
                    <ol className="pb-2">
                      <li className="pb-1">
                        We regularly review and, if appropriate, update this privacy policy from time to time, and as our services and use
                        of personal data evolves.
                      </li>
                      <li className="pb-1">We will update the date of this document each time it is changed.</li>
                    </ol>
                  </li>
                  <li className="pb-1">
                    <strong>Last update</strong>
                    <p className="pb-1 m-0">
                      This privacy policy was last updated on 27 October 2024 and is effective immediately. We reserve the right to amend
                      this policy as and when is needed, whether this be internal business changes or changes in the law.
                    </p>
                    <p className="pb-1 m-0">Requesting your personal data or contacting us</p>
                    <p className="pb-1 m-0">
                      Any requests to view or change personal information collected about you please contact our Data Protection Officer
                    </p>
                    <p className="pb-1 m-0">
                      Mathew Bishop
                      <br />
                      Mathew.bishop@lumensol.co.uk
                    </p>
                  </li>
                </li>
              </ol>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default PrivacyPolicy;
