import React from "react";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import Login from "../auth/login";
import NewUser from "../auth/new-user";
import PasswordForgot from "../auth/password-forgot";
import PasswordReset from "../auth/password-reset";
import PrivacyPolicy from "../common/privacy-policy";
import TermsOfUse from "../common/terms-of-use";
import CenteredContainer from "../containers/centered-container";
import NotFound from "../not-found";

const PreauthLayout = () => {
  return (
    <>
      <CenteredContainer>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/password-forgot" exact component={PasswordForgot} />
          <Route path="/password-reset" exact component={PasswordReset} />
          <Route path="/new-user" exact component={NewUser} />
          <Route path="/privacypolicy" exact component={PrivacyPolicy} />
          <Route path="/termsofuse" exact component={TermsOfUse} />
          <Route component={NotFound} />
        </Switch>
        <div className="text-primary p-2">
          <Link to="/">Login</Link> | <Link to="/privacypolicy">Privacy policy</Link> | <Link to="/termsofuse">Terms of use</Link>
        </div>
      </CenteredContainer>
    </>
  );
};

export default withRouter(PreauthLayout);
